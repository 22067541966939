@import url("tokens.css");

/*
Primitives
*/

.website-h1 {
  color: var(--website-color-h1-on-light);
  font-family: var(--website-font-family);
  font-size: var(--website-font-size-h1);
  font-weight: var(--website-font-weight-h3);
  line-height: 58px;
  word-wrap: break-word;
}

.website-h2 {
  color: var(--website-color-h1-on-light);
  font-family: var(--website-font-family);
  font-size: var(--website-font-size-h2);
  font-weight: var(--website-font-weight-h3);
  line-height: 40px;
  word-wrap: break-word;
}

.website-p {
  opacity: 0.8;
  color: var(--website-color-p-on-light);
  font-size: 20px;
  font-family: var(--website-font-family);
  font-weight: var(--website-font-weight-h1);
  word-wrap: break-word;
  margin-top: 16px;
}

.website-button {
  color: var(--website-color-h1);
  background-color: var(--website-color-highlight);
  border-radius: 50px;
  font-size: 18px;
  font-family: var(--website-font-family);
  padding: 8px 24px;
  margin-top: 24px;
  font-weight: var(--website-font-weight-h3);

  &:hover {
    background-color: var(--secondary);
    color: var(--secondary5);
  }
}

.website-section {
  background-color: var(--landing-page-darkest-purple);
  padding-top: 114px;
  padding-bottom: 114px;
}

.website-section > .website-h1 {
  color: var(--website-color-h1);
  text-align: center;
  font-weight: var(--website-font-weight-h1);
}

.website-section > .website-p {
  color: var(--website-color-p);
  text-align: center;
  padding-top: 24px;
  font-weight: var(--website-font-weight-p);
  max-width: 736px;
  margin: auto;
}

.website-form {
  width: 800px;
  max-width: 90%;
  margin: auto;
  margin-top: 40px;
}

.website-form-button {
  border-radius: 4px;
  padding: 14px;
  background: var(--website-color-highlight);
  color: var(--website-color-h1);
  font-size: 16px;
  font-family: var(--website-font-family);
  font-weight: var(--website-font-weight-h3);
  width: 100%;
}

/* 
Blog Post
*/

.blog-post-content {
  text-align: left;
  width: 100%;
  max-width: 736px;
}

.blog-post-content blockquote {
  border: none !important;
  background-color: #f1edff;
  border-radius: 16px;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.blog-post-content blockquote p {
  color: #8029b3;
  margin-bottom: 0;
}

.blog-post-content p {
  margin-bottom: 16px;
}

.blog-post-content img {
  margin-bottom: 32px;
}

.blog-post-content h1 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 48px;
}

.blog-post-content h2 {
  font-weight: 600;
  font-size: 24px;
}

.blog-post-content blockquote {
  border-left: 4px solid lightgray;
  padding-left: 16px;
  margin-left: 0;
  font-style: italic;
}

.blog-post-content ul,
.blog-post-content ol {
  margin: 0;
  margin-bottom: 16px;
  padding-left: 2em;
}

.blog-post-content li > p {
  margin-bottom: 0px;
}

.blog-post-content ul {
  list-style-type: disc;
}

.blog-post-content a {
  color: #0078d4;
  text-decoration: underline;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  transform: rotateY(0deg);
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* Interactive demo */
.sl-embed {
  position: relative;
  padding-bottom: calc(48.91% + 27px);
  width: 100%;
  height: 0;
  transform: scale(1);
}

.sl-demo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(63, 95, 172, 0.35);
  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
}

/* 
 *
 * Responsive styles 
 *
 */
@media screen and (min-width: 700px) {
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

/* Mobile breakpoint */
@media screen and (max-width: 390px) {
  .website-h1 {
    font-size: var(--website-font-size-h1-mobile);
    line-height: 40px;
  }

  .website-p {
    font-size: var(--website-font-size-p-small);
    padding: 0 16px;
  }

  .website-newsletter-signup {
    width: 100%;
    max-width: none;
  }

  .website-section {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
