@import url("tokens.css");
@import url("website.css");

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--background);
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

.appContainer {
  min-height: 100vh;
}

tbody > tr:hover {
  background-color: rgba(227, 228, 229, 0.3);
}

.noHover:hover {
  background-color: var(--background);
}

.taggedUser {
  border-radius: 8px;
  background: var(--secondary4);
  color: var(--secondary);
  padding: 2px 4px;
}

.pdfView {
  width: 55vw;
  height: 90vh;
  border: 0px;
}

@media screen and (max-width: 800px) {
  .pdfView {
    width: 90vw;
  }
}

/* date picker styling */
.react-datepicker__month-container .react-datepicker__header {
  background-color: var(--secondary);
}

.react-datepicker__month-container .react-datepicker__current-month {
  color: white;
}

.react-datepicker__day-names .react-datepicker__day-name {
  color: white;
}

.react-datepicker__month .react-datepicker__day--keyboard-selected {
  background-color: var(--secondary3);
  color: white;
}

.react-datepicker__month .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: var(--secondary);
  color: white;
}

.react-datepicker__month .react-datepicker__day--selected {
  background-color: var(--secondary);
  color: white;
}

.document-preview {
  all: revert;
  font-family: "Times New Roman", Times, serif;
  font-size: 1em;
}

.document-preview * {
  all: revert;
}

.markdown-preview h2 {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
}

.markdown-preview p {
  margin-top: 12px;
  margin-bottom: 12px;
}

.markdown-preview :first-child {
  margin-top: 0;
}

.intake-requirement {
  transition: background-color 0.2s ease-in-out;
  background-color: white;
}

.requirement-highlight {
  animation: flash 1s ease-in-out;
}

@keyframes flash {
  0% {
    background-color: white;
  }
  50% {
    background-color: var(--green-1);
  }
  100% {
    background-color: white;
  }
}
