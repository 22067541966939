/*

  /\  
 /  \ 
/____\
\    /
 \  / 
  \/  

INTEGRAL DESIGN TOKENS

Design tokens are the visual design atoms of the design system — specifically, they are named entities that store visual design attributes. We use them in place of hard-coded values (such as hex values for color or pixel values for spacing) in order to maintain a scalable and consistent visual system for website and user interface development.

Token declarations should follow the following format:
--<project>-<property>-<element>-<variant>

where:
- <project> is some namespace, i.e. `ui`, `website`
- <property> is the property of the element, i.e. `color`, `font-size`
- <element> is the HTML element tag, i.e. `h1`, `button`
- <variant> is the token variant, i.e. `primary`, `secondary`

*/

:root {
  /* 
  ********************************
            PLATFORM UI
  ********************************
  */

  /* Typography */
  --ui-font: "SF Pro", "SF Pro Text", "Arial", sans-serif;

  /* Color */
  --primary: #16182d;
  --primary5: #dddeee;
  --secondary: #8c4ccd;
  --secondary3: #b388dd;
  --secondary4: #d9c3ee;
  --secondary5: #f2ebf9;
  --success: #248450;
  --warning: #ffc800;
  --error: #ef5d5d;
  --orange-1: #ffdbb2;
  --orange-2: #995200;
  --red-1: #facece;
  --red-2: #b91312;
  --gray-1: #e6e6e6;
  --gray-2: #737373;
  --yellow-1: #ffefb2;
  --yellow-2: #665000;
  --yellow-3: #b38f00;
  --green-1: #bddaca;
  --green-2: #195c38;
  --blue-1: #c7e0f4;
  --blue-2: #2a4d69;
  --background: #edf2f7;
  --side-menu-background: #171717;
  --card-border: #e3e4e5;
  --text: #333333;
  --text2: #737373;
  --text3: #a3a3a3;
  --table-background: #fafafa;

  --ui-color-header: #454467;
  --ui-color-card-border: #e2e8f0;
  --ui-color-side-menu: #ddbcff;
  --ui-color-side-menu-active: #171717;
  --ui-color-card-row-hover: #f9f7f7;

  /* Radius */
  --ui-radius-card: 8px;

  /* Spacing */
  --ui-spacing-card-vertical: 16px;
  --ui-spacing-card-horizontal: 24px;

  /* Buttons */
  --ui-color-background-button-primary: #8a4acd;
  --ui-color-background-button-primary-active: #7e2dd4;
  --ui-color-background-button-primary-disabled: #e6e2eb;
  --ui-color-text-button-primary-disabled: #9296ad;

  --ui-color-text-button-secondary-disabled: #9296ad;
  --ui-color-border-button-secondary-disabled: #e2e8f0;

  --ui-color-background-button-tertiary-hover: #edf2f6;
  --ui-color-background-button-tertiary-active: #e2e8f0;
  --ui-color-text-button-tertiary-disabled: #9296ad;

  /* 
  ********************************
      LANDING PAGE (deprecated)
  ********************************
  */
  /* Typography */
  --landing-page-font: "Nunito Sans", "Arial", sans-serif;

  /* Color */
  --landing-page-light-purple: #150c3a;
  --landing-page-dark-purple: #0c0926;
  --landing-page-darkest-purple: #060b27;

  /* 
  ********************************
              WEBSITE
  ********************************
  */

  /* Dimensions */
  --website-width-max: 1120px;

  /* Typography */
  --website-font-family: "Nunito Sans", "Arial", sans-serif;
  --website-font-size-h1: 56px;
  --website-font-size-h1-mobile: 32px;
  --website-font-size-h2: 36px;
  --website-font-size-h2-mobile: 24px;
  --website-font-size-h3: 36px;
  --website-font-size-p: 20px;
  --website-font-size-p-small: 16px;
  --website-font-size-special-feature-text: 20px;

  --website-font-weight-h1: 400;
  --website-font-weight-h2: 600;
  --website-font-weight-h3: 700;
  --website-font-weight-p: 400;
  --website-font-weight-special-feature-text: 400;

  /* Color */
  --website-color-highlight: #6325e3;
  --website-color-highlight-alt: #763bb4;
  --website-color-h1: #ffffff;
  --website-color-p: #8f9bb7;
  --website-color-h1-on-light: #151934;
  --website-color-p-on-light: #272b47;
  --website-color-background-light: #f9fbff;
  --website-color-background-base: #0b0825;
  --website-color-special-feature: #d171ff;
  --website-color-avatar: #542389;
  --website-color-background-button-hover: #9271d5;
  --website-color-stroke-link: #e3d3ff;
  --website-color-stroke-link-hover: #c017fb;
  --website-color-stroke-alt: #e9deff;
  --website-color-footer-link-base: #9296ad;
  --website-color-card-border: rgba(99, 37, 227, 0.18);
  --website-color-card-border-dark: rgba(99, 37, 227, 0.45);
  --website-color-hr: rgba(255, 255, 255, 0.16);
  --website-color-button-border: #38344f;
  --website-color-border-dark: #282d45;
  --website-color-button-light: rgba(240, 233, 255, 0.07);
  --website-color-section: #fbfcff;

  /* Breakpoint */
  --website-breakpoint-mobile: 390px;

  /* Gradient */
  --website-gradient-background-image: radial-gradient(45% 45% at 126% 86%, #32cafd6e 0%, #0b082500 100%), radial-gradient(73% 73% at 1% 50%, #0b0825e6 0%, #6325e329 0%, #0b0825 50%),
    linear-gradient(125deg, #0b0825 1%, #0b0825 100%);
}
